<template>
    <BasicSkeleton :showPlaceholder="pending">
      <div class="exp-price" v-if="!pending && localprice?.bruttoPrice !== undefined">
        <div v-if="!pending" class="beforeprice">
          <slot name="beforePrice" :finalPrice="localprice"></slot>
        </div>
        <div class="theme-price" v-if="!pending && localprice?.bruttoPrice !== undefined">
          <div
            class="formerPrice-comp"
            :class="formerPriceClass + (addsReserveSpace ? ' formerprice-placeholder' : '')"
          >
            <template
              v-if="localprice.itemOnDisplay && localprice.itemOnDisplayDescription !== null"
            >
              <div
                class="flex"
                :class="addsReserveSpace ? 'justify-center exhibit-placeholder' : ''"
              >
                <img
                  class="articleExhibit mr-2"
                  alt="Austeller Info"
                  src="https://cdn.expert.de/53/d6/3c/4013451a75c98791c284c0f24b8da8c883/exhibit.png"
                />
                <img
                  v-if="exhibitInfo"
                  class="ic-icon"
                  @click.prevent="itemOnDisplayHover = !itemOnDisplayHover"
                  @mouseleave="$device.isDesktop ? (itemOnDisplayHover = false) : false"
                  @mouseover="$device.isDesktop ? (itemOnDisplayHover = true) : false"
                  src="~/assets/mediums/Icons/circle-info-light.svg"
                  alt="Aussteller Info"
                  style="min-height: 25"
                />
              </div>
              <div
                v-if="itemOnDisplayHover == true"
                class="exhibition-hover-info border z-20"
                :class="popOverClass"
              >
                {{ localprice.itemOnDisplayDescription }}
              </div>
            </template>
            <template v-else>
              <div
                v-if="
                addsReserveSpace ||
                localprice.itemOnDisplay ||
                localprice.itemOnDisplayDescription == null
              "
                class="exhibit-placeholder flex justify-center"
              ></div>
            </template>
            <template
              v-if="
              localarticle?.formerPriceState !== '' &&
              localarticle?.formerPriceState !== null &&
              enableUVPLabel &&
              formerPriceDifferenceText?.length > 2 &&
              localarticle?.formerBruttoPrice > localprice.bruttoPrice
            "
            >
              <div
                class="flex gap-2"
                :class="
                addsReserveSpace ? 'justify-center exhibit-placeholder' : 'items-center'
              "
              >
                <div class="formerPrice">
                  <div class="relative">
                    <div class="line-through"></div>
                    &nbsp;{{ localarticle?.formerPriceState }}&nbsp;{{
                      localarticle?.formerBruttoPrice
                    }}€
                  </div>
                </div>
                <template v-if="enableUVPFlyOut == true">
                  <div class="uvp-info relative flex">
                    <img
                      class="w-5"
                      alt="Unverbindliche Preis Empfehlung"
                      src="~/assets/mediums/Icons/circle-info-light.svg"
                      @mouseleave="$device.isDesktop ? (showUvpTextField = false) : false"
                      @mouseover="$device.isDesktop ? (showUvpTextField = true) : false"
                      @click.prevent="showUvpTextField = !showUvpTextField"
                    />
                    <div v-if="showUvpTextField" class="uvp-hover-info whitespace-nowrap">
                      UVP des Herstellers
                    </div>
                  </div>
                </template>
                <div class="sales-label pl-2" v-if="enableUVPLabel && enableUVPFlag">
                  <div v-if="formerPriceDifferenceText !== undefined && formerPriceDifferenceText !== null"
                       class="formerPricePercentTriangle">
                    <div class="formerPricePercentHighlightBox">
                      <div class="formerPricePercent">{{ formerPriceDifferenceText }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="text-primary" :class="displayPriceDesignClasses">
            <ArticlePubDataWrapper
              class="exp_price"
              :createdOn="localprice?.createdOn"
              :lastUpdated="localprice?.lastUpdated"
              :cacheLevel="localprice?.cacheLevel"
              :pubSource="localprice?.pubSource">
              {{
                (checkoutPrice % 1 === 0
                  ? checkoutPrice + ".-"
                  : parseFloat(checkoutPrice).toFixed(2)) +
                (localprice.monthly ? " pro Monat" : isWarranty ? " einmalig" : "")
              }}
            </ArticlePubDataWrapper>
            <ArticleEnergyEfficiency
              class="text-sm"
              v-if="showEEL"
              :showDataFile="showDataFile"
              :article="localarticle"
              style="margin-left: 0.5em"
            />
          </div>
          <template v-if="pprice?.basicPrice?.gross && pprice?.basicPriceUnit">
            <div class="text-xs text-paragraph-light">
              ({{
                (pprice.basicPrice?.gross % 1 === 0
                  ? pprice.basicPrice?.gross + ".-"
                  : parseFloat(pprice.basicPrice?.gross).toFixed(2)) +
                ("€ pro " + pprice.basicPriceUnit)
              }})
            </div>
          </template>
          <template
            v-if="
            useCurrentStore()?.value?.storeId == '2879130' &&
            useRoute().fullPath.includes(localarticle?.webcode) &&
            showOnlinePriceInfo
          "
          >
            <span class="text-sm font-bold">Onlinepreis </span>
          </template>
          <ArticleDeliveryInfo :enableDeliveryModal="enableDeliveryModal" :showShipmentInformation="showShipmentInformation"
                               :pprice="pprice" :showModal="showModal" :deliveryInfoClass="deliveryInfoClass" :showShippingEstimate="showShippingEstimate"
                               :addsReserveSpace="addsReserveSpace" :localprice="localprice" :lineBreakDeliveryInfo="lineBreakDeliveryInfo"
                               :shipmentBruttoPrice="(localprice.shipmentArray?.length > 0 ? localprice.shipmentArray[0].shipmentBruttoPrice : null)" />
        </div>
  
        <div v-if="!pending" class="afterprice">
          <slot name="afterPrice" :finalPrice="localprice"></slot>
          <ArticleExpStore
            v-if="localprice?.showStoreName"
            :storeId="localprice?.showStoreName"
          ></ArticleExpStore>
        </div>
      </div>
      <template #placeholder>
        <SkeletonPrice />
      </template>
    </BasicSkeleton>
  </template>
  <script setup lang="ts">
  import { getPrice } from "~/composables/ExpApi/expPrice";
  const props = defineProps({
    localarticle: {
      type: Object,
      watch: true,
    },
    lineBreakDeliveryInfo: {
      type: Boolean,
      default: false,
    },
    showEEL: {
      type: Boolean,
      default: false,
    },
    addsReserveSpace: {
      type: Boolean,
      default: false,
    },
    displayPriceDesignClasses: {
      type: String,
      default: "text-5xl relative flex gap-4",
    },
    deliveryInfoClass: {
      type: String,
      default: "",
    },
    formerPriceClass: {
      type: String,
      default: "flex gap-2 flex-wrap relative items-center",
    },
    popOverClass: {
      type: String,
      default: "w-[250px]",
    },
    pprice: {
      type: Object,
      watch: true,
    },
    showShipmentInformation: {
      type: Boolean,
      default: true,
    },
    isWarranty: {
      type: Boolean,
      default: false,
    },
    enableDeliveryModal: {
      type: Boolean,
      default: true,
    },
    enableUVPLabel: {
      type: Boolean,
      default: true,
    },
    enableUVPFlyOut: {
      type: Boolean,
      default: true,
    },
    enableUVPFlag: {
      type: Boolean,
      default: true
    },
    exhibitInfo: {
      type: Boolean,
      default: true,
    },
    uvpTextField: {
      type: Boolean,
      default: false,
    },
    showOnlinePriceInfo: {
      type: Boolean,
      default: true,
    },
    showDataFile: {
      type: Boolean,
      default: false
    },
    showShippingEstimate: {
      type: Boolean,
      default: false
    },
    discountedPrice: {
      type: Number,
      default: 0
    }
  });
  const localprice = ref(props?.pprice);
  const checkoutPrice = computed(() => {
    let currentPrice = localprice?.value?.bruttoPrice;
    if (props.discountedPrice !== undefined && props.discountedPrice !== 0) {
      currentPrice = props.discountedPrice
    }
    return currentPrice;
  }); 

  watch(() => props?.pprice, () => {
    localprice.value = props?.pprice
  })
  const pending = ref(true);
  
  const itemOnDisplayHover = ref(false);
  const showUvpTextField = ref(false);
  const showModal = ref(false);
  
  const minPercentDifference = 10;
  const maxPercentDifference = 70;
  const minPriceForPercentage = 50;
  const minAbsolutDifference = 5;
  if (props.pprice === null || props.pprice === undefined) {
    console.debug("fetching price because none was given");
    const { pending: checkPending, data: neuerPreis } = await getPrice(
      props?.localarticle?.articleId,
      useCurrentStore(),
      false,
      false
    );
    pending.value = false;
    if (neuerPreis.value) localprice.value = neuerPreis.value;
  } else {
    pending.value = false;
  }
  
  const formerPriceDifferenceText = computed(() => {
    let calculation = 100 - (localprice?.value?.bruttoPrice / props.localarticle?.formerBruttoPrice) * 100;
    let result = calculation.toFixed(0);
    if (localprice?.value?.bruttoPrice >= minPriceForPercentage) {
      if (calculation > minPercentDifference && calculation < maxPercentDifference)
        return "-" + result + "%";
    } else {
      if (
        props.localarticle?.formerBruttoPrice - localprice?.value?.bruttoPrice >= minAbsolutDifference &&
        calculation < maxPercentDifference
      )
        return "-" + result + "%";
    }
  });
  </script>
  