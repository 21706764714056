<template>
  <div class="grow">
    <form @submit.prevent="handleSearch(searchWord)" role="search">
      <div id="searchbox">
        <input
          id="termsuggest-input"
          type="search"
          name="q"
          v-model="searchWord"
          aria-autocomplete="list"
          aria-controls="suggestionList"
          aria-haspopup="listbox"
          enterkeyhint="search"
          autocapitalize="off"
          autocomplete="off"
          autofocus
          @keydown="(event) => handleSearchKeyInput(event)"
          @input="useSuggestionsOpenState().value = true"
          placeholder=" Suchen nach Produkten, Marken, Web-Code & mehr..."
        />
        <button class="px-4 border-l" @click="handleSearch(searchWord)" aria-label="Suche starten">
          <BasicIcon :name="'magnifying-glass-solid'" class="inline-block w-5 text-primary" />
        </button>
      </div>
    </form>
    <ClientOnly v-if="!$device?.isLighthouse">
      <div class="relative">
        <div
          v-if="termSuggestions?.termSuggest && termSuggestions.termSuggest.length > 0"
          v-show="useSuggestionsOpenState().value"
          ref="suggestionsBox"
          class="termSuggestions grid border-t-white absolute w-full bg-white py-4 border-white shadow right-0 top-full"
          :class="{ 'grid-cols-12': termSuggestions?.termSuggest?.length > 0 }">
          <div
            v-if="termSuggestions?.termSuggest?.length > 0"
            id="suggestionList"
            role="listbox"
            aria-label="Suchvorschläge"
            class="col-span-12 lg:col-span-5"
            @keydown="(event) => handleSearchKeyInput(event)">
            <template v-for="(suggestion, index) in termSuggestions?.termSuggest" :key="suggestion.label">
              <div
                :id="`suggestion-${index}`"
                role="option"
                tabindex="0"
                :aria-selected="selectedSuggestionIndex === index"
                @mouseover="setSuggestionCursor(index as number)"
                @click="editSearch(suggestion.label, 'Suchvorschlag')">
                <div
                  class="suggest-label inline-block hover:bg-secondary px-4 py-2 w-11/12 text-left"
                  v-html="suggestion.highlighting"
                />
                <div class="arrow-icon">
                  <BasicIcon
                    class="w-4 h-4 text-primary"
                    :name="'arrow-up-left-solid'"
                  />
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="articleSuggestions?.articleSuggest && articleSuggestions.articleSuggest.length > 0"
            class="hidden lg:block px-4 border-l"
            :class="articleSuggestions?.articleSuggest && articleSuggestions.articleSuggest.length > 0 ? 'col-span-7' : ''">
            <template v-for="article in articleSuggestions?.articleSuggest" v-bind:key="article.article.articleId">
              <NuxtLink
                :to="{
                  path: baseDir + article.article.slug,
                  query: {
                    suggest: 'true',
                    suggestType: 'Artikel',
                    sq: searchWord,
                  },
                }"
                class="border-t first:border-t-0 grid grid-cols-7 grid-rows-2 py-2 gap-1"
                @click="useMarker('SearchClickArticleSuggest'); useSuggestionsOpenState().value = false;">
                <div class="col-start-1 col-span-2 row-start-1  row-span-2">
                  <div class="relative">
                    <div class="absolute top-0 left-0 w-8">
                      <PromotionBubble :articleId="article.article.articleId" :price="article.price" />
                    </div>
                    <div class="absolute bottom-0 left-0 w-8">
                      <PromotionBubble :articleId="article.article.articleId" :price="article.price" :listIndex="1" />
                    </div>
                    <ArticleExpMedium
                      class="max-h-20 mx-auto"
                      :mediumId="article.article.primaryImage"
                      preset="preview"
                    />
                  </div>
                </div>
                <ArticleEnergyEfficiency
                  class="col-start-1 col-span-2 row-start-2 row-span-2 text-xs"
                  :article="article?.article"
                  :labelTextSize="28"
                  :labelSvgSize="20"
                />
                <div class="col-span-3 row-span-2">
                  <div class="title text-sm font-bold pb-2">
                    {{ article.article.title }}
                  </div>
                  <div class="articlestatus text-xs">
                    <ArticleExpArticleState
                      :showButtons="false"
                      :price="article.price"
                      :key="useCurrentStore()?.value?.id"
                    />
                  </div>
                </div>
                <ArticleExpPriceBasic
                  :localarticle="article.article"
                  :showFormerPrice="false"
                  class="text-right col-span-2 row-span-2"
                  :enableUVPLabel="true"
                  :exhibitInfo="false"
                  :enableUVPFlag="false"
                  :enableUVPFlyOut="false"
                  :formerPriceClass="'flex flex-col items-end gap-2'"
                  :showShipmentInformation="false"
                  :displayPriceDesignClasses="'text-2xl'"
                />
              </NuxtLink>
            </template>
            <NuxtLink
              class="block btn-primary w-full text-center font-bold text-sm mt-4"
              :to="{
                path: '/shop/suche',
                query: {
                  q: searchWord,
                  suggest: 'true',
                  suggestType: 'Alle Suchergebnisse',
                  sq: searchWord,
                },
              }"
              @click="useMarker('SearchClickAllSearchResults')"
              :external="false">
              Alle Suchergebnisse anzeigen
            </NuxtLink>
          </div>
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { onClickOutside, watchThrottled } from "@vueuse/core";
import type { ArticleSuggestResponse, TermSuggestResponse } from "~/models/Suggestions";

const storeRef = inject(ProvideNames.CURRENT_STORE_KEY);
const updateFunction = storeRef?.updateFunction || console.error;
const baseDir: string = inject(ProvideNames.BASE_DIR) ?? '/';

const searchWord = ref('')
let hasStashedSearchWord: boolean;
const stashedSearchWord = ref("");
const scrolledSuggestion = ref("");

const queryForTerms = ref("");
const queryForArticles = ref("");
const suggestionsBox = ref();

onClickOutside(suggestionsBox, event => useSuggestionsOpenState().value = false);

const { data: termSuggestions } = await useLazyFetch<TermSuggestResponse>(useRuntimeConfig()?.public.apiUrl + "/api/search/suggests/term", {
  query: {
    q: queryForTerms,
    storeId: useStoreCookie()
  },
});
const { data: articleSuggestions } = await useLazyFetch<ArticleSuggestResponse>(useRuntimeConfig()?.public.apiUrl + "/api/search/suggests/article", {
  query: {
    q: queryForArticles,
    storeId: useStoreCookie(),
    noArticles: useDevice().isMobile
  },
});

watchThrottled(
  searchWord,
  (val) => {
    console.debug("suchwort changed", searchWord);
    if(hasStashedSearchWord) {
      queryForTerms.value = stashedSearchWord.value;
    } else {
      queryForTerms.value = searchWord.value;
    }
    queryForArticles.value = searchWord.value;
  },
  { throttle: 250 }
);

const handleSearch = (token: string) => {
  if (token?.toLowerCase() == '#einruckeln') {
    useEasterEgg('einruckeln');
  } else if (token?.toLowerCase() == '#btdev') {
    useEasterEgg('btdev');
    return;
  } else if (token?.toLowerCase() == '#pride') {
    useEasterEgg('pride');
    return;
  }
  useMarker('SearchUsed');
  handleHardlinkCheck(token);
  useSuggestionsOpenState().value = false;
  document?.getElementById('termsuggest-input')?.blur()
}

const handleHardlinkCheck = async (value, suggest?) => {
  const webCodeRegEx = /^\d{11}$/;
  const eanRegEx = /^\d{13}$/;

  if (value?.toLowerCase()?.trim() == 'zentralshop1') {
    return await switchToCentralStore(updateFunction);
  } else if (value?.startsWith('e_')) {
    const { data: rawResult } = await useFetch(`/api/search/article/id/${value}?storeId=${useCurrentStore()?.value?.id}`);
    const mappedArticle = rawResult?.value?.article;
    if (mappedArticle?.slug == undefined) {
      await navigateTo({
        path: '/shop/suche',
        query: {
          q: value,
          suggestType: suggest ? suggest : undefined,
          suggest: suggest ? 'true' : undefined
        }
      });
    } else {
      await navigateTo({
        path: baseDir + mappedArticle?.slug,
        query: {
          sq: value,
          queryType: 'webcode'
        }
      }, { external: false, redirectCode: 307 });
    }
  } else if (webCodeRegEx.test(value)) {
    const { data: rawResult } = await useFetch(`/api/search/article/webcode/${value}?storeId=${useCurrentStore()?.value?.id}`);
    const mappedArticle = rawResult?.value?.article;
    if (mappedArticle?.slug == undefined) {
      await navigateTo({
        path: "/shop/suche",
        query: {
          q: value,
        },
      });
    } else {
      await navigateTo({
        path: baseDir + mappedArticle?.slug,
        query: {
          sq: value,
          queryType: "webcode",
        },
      }, { external: false, redirectCode: 307 });
    }
  } else if (eanRegEx.test(value)) {
    const { data: rawResult } = await useFetch(`/api/search/article/ean/${value}?storeId=${useCurrentStore()?.value?.id}`);
    const mappedArticle = rawResult?.value?.article;
    if (mappedArticle?.slug == undefined) {
      await navigateTo({
        path: "/shop/suche",
        query: {
          q: value,
        },
      });
    } else {
      await navigateTo({
        path: baseDir + mappedArticle?.slug,
        query: {
          sq: value,
          queryType: "webcode",
        },
      }, { external: false, redirectCode: 307 });
    }
  } else {
    const { data } = await useFetch(`/api/search/redirect/${value}`);
    if (data?.value?.redirectUrl && data.value.redirectUrl.length > 0) {
      const redirectPath = data?.value?.redirectUrl?.split("?")?.at(0);
      const redirectQueryParams = data?.value?.redirectUrl?.split("?")?.at(1);

      const isExternal = (!redirectPath?.includes("unsere-produkte") && !redirectPath?.includes("suche"));
      const params = new URLSearchParams(redirectQueryParams || '');

      if (redirectQueryParams) {
      params.append("sq", value);
      }

      if (data?.value?.redirectType) {
      params.append("queryType", data?.value?.redirectType);
      }

      console.debug(
        "queries and final path",
        isExternal,
        params,
        redirectPath + (params.size > 0 ? "?" + params.toString() : "")
      );

      await navigateTo(redirectPath + (params.size > 0 ? "?" + params.toString() : ""), {
        external: isExternal,
        redirectCode: 307,
      });
    } else {
      await navigateTo({
        path: "/shop/suche",
        query: {
          q: value,
          suggestType: suggest ? suggest : undefined,
          suggest: suggest ? 'true' : undefined
        },
      });
      useSearchWord().value = value;
      searchWord.value = value;
    }
  }
}

const editSearch = (suggestion, suggest?) => {
  useMarker('SearchUsed' + suggest + 'Suggest');
  searchWord.value = suggestion;
  useSearchWord().value = suggestion;
  handleHardlinkCheck(suggestion, suggest);
  useSuggestionsOpenState().value = false;
}

const selectedSuggestionIndex = ref(0);
const setSuggestionCursor = (index: number): void => {
  const selectedSuggestion: HTMLDivElement = document.getElementById(`suggestion-${index}`) as HTMLDivElement;
  const selectedSuggestionLabel: HTMLDivElement = selectedSuggestion?.childNodes[0] as HTMLDivElement;
  scrolledSuggestion.value = selectedSuggestionLabel.textContent || '';
  queryForArticles.value = selectedSuggestionLabel.textContent || '';
  selectedSuggestion.focus();
}

const moveSuggestionCursor = (directionIncrement: number): void => {
  const maxIndex = termSuggestions?.value?.termSuggest?.length - 1;
  const newIndex = selectedSuggestionIndex.value + directionIncrement;
  if (newIndex < 0) {
    selectedSuggestionIndex.value = maxIndex;
  } else if (newIndex > maxIndex) {
    selectedSuggestionIndex.value = 0;
  } else {
    selectedSuggestionIndex.value += directionIncrement;
  }

  setSuggestionCursor(selectedSuggestionIndex.value);

  if(!hasStashedSearchWord) {
    stashedSearchWord.value = searchWord.value;
    hasStashedSearchWord = true;
  }
  searchWord.value = scrolledSuggestion.value;
}

const resetSuggestions = () => {
  selectedSuggestionIndex.value = -1;
  stashedSearchWord.value = "";
  hasStashedSearchWord = false;
  useSuggestionsOpenState().value = false;
}

const handleSearchKeyInput = (event: KeyboardEvent): void => {
  const searchInput = document.getElementById('termsuggest-input');
  switch (event.key) {
    case 'ArrowUp':
      event.preventDefault();
      moveSuggestionCursor(-1)
      break;
    case 'ArrowDown':
      event.preventDefault();
      moveSuggestionCursor(1)
      break;
    case 'Escape':
      event.preventDefault();
      resetSuggestions();
      searchWord.value = '';
      searchInput?.focus();
      break;
    default:
      resetSuggestions();
      searchInput?.focus();
      break;
  }
}
</script>

<style scoped>
[role=option]:focus {
  outline: none;
}

[role=option]:focus .suggest-label {
  outline: rgb(16, 16, 16) auto 1px;
}

#termsuggest-input {
  @apply pl-4 w-full bg-secondary-light text-sm outline-none focus:text-primary text-paragraph-light;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: 6px;
  min-height: unset;
  resize: none;
  overflow: hidden;
  font-family: Lato;
  height: 40px;
}

#termsuggest-input::-webkit-search-cancel-button {
  @apply hidden lg:inline-block cursor-pointer;
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  background:
    linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 43%, #ff5e19 45%, #ff5e19 55%, rgba(0, 0, 0, 0) 57%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(135deg, transparent 0%, transparent 43%, #ff5e19 45%, #ff5e19 55%, transparent 57%, transparent 100%);
}

#searchbox {
  @apply bg-secondary-light flex justify-between py-1 shadow items-center;
}

.header .promo-bubble:hover {
  transform: scale(4);
  opacity: 1;
}

.header .energyEfficiency .listDocuments .entry {
  font-size: 0.69rem;
}

#q-downshift #q-input {
  min-height: 52px;
  padding: 12px 36px;
  background-color: white;
  z-index: 1;
}
</style>