<template>
  <template v-for="additionalItem in item?.additionalItems?.items" :key="additionalItem.id">
    <template v-for="warranty in warranties?.warrantyList" :key="warranty.typeName">
      <template v-if="warranty.typeName == additionalItem.id && (!monthlyOnly || warranty.monthlyBruttoPrice > 0)">
        <div :class="containerClass">
          <span>
            <BasicIcon v-if="showIcon" name="check-regular" class="w-4 text-success align-middle mr-1" /> {{ warranty.title }}
            <template v-if="infoBeforePrice">
              <span>
                &nbsp;<BasicIcon name="circle-info-light" class="w-4 tooltip-top align-text-bottom" data-html="true"
                  data-tooltip="Nach dem Kaufabschluss&#160;erhältst du alle weiteren Informationen separat von der Wertgarantie&#160;SE."/>
              </span>
              <!--&nbsp;<BasicIcon v-if="warranties" @click.stop='openWarrantyInformation(warranty)' :name="'circle-info-light'" class="w-4 align-text-bottom cursor-pointer" />-->
            </template>
            <template v-else>
              – 
            </template>
          </span>
          <span v-if="warranty.bruttoPrice > 0">{{ (warranty.bruttoPrice * item?.quantity).toFixed(2) + ' € einmalig'}}</span>
          <span v-if="warranty.monthlyBruttoPrice > 0">{{ (warranty.monthlyBruttoPrice * item?.quantity).toFixed(2) + ' € pro Monat'}}</span>
          <template v-if="!infoBeforePrice">
            &nbsp;<BasicIcon v-if="warranties" @click.stop='openWarrantyInformation(warranty)' :name="'circle-info-light'" class="w-4 align-text-bottom cursor-pointer" />
          </template>
        </div>
        <teleport to="body">
          <div class="warranty" v-if="warranties">
            <Modal v-if="showWarranty == warranty.typeName" @close-modal="showWarranty = false">
              <template #icon>x</template>
              <strong>{{ warrantyTitle }}</strong>
              <p>{{ warrantyDescription }}</p>
              <div v-for='files in warrantyUrl' :key='files'>
                <a :href='files.fileUrl' class="warranty-link"  target='_blank' :title="warrantyTitle">{{ files.fileName }}</a>
              </div>
              <ArticleExpMedium mediumId="65f9af5be7b128000186bddc"></ArticleExpMedium>
            </Modal>
          </div>
        </teleport>
      </template>
    </template>
    <div v-if="showOldApplianceRemoval && additionalItem.id.indexOf('OLD_APPLIANCES_REMOVAL') > -1" class="col-start-1 lg:col-start-2 p-4 pt-0 lg:px-0 -mt-4 lg:col-span-3 col-span-5 font-bold relative">
      <BasicIcon name="check-regular" class="w-4 text-success align-middle mr-1" /> Altgeräte-Mitnahme - 
      <span class="text-success font-bold">
        GRATIS
      </span>
      <BasicIcon :name="'circle-info-light'" class="w-4 -mb-1"
          @click.prevent="itemOnDisplayHover = !itemOnDisplayHover"
          @mouseleave="$device.isDesktop ? (itemOnDisplayHover = false) : false"
          @mouseover="$device.isDesktop ? (itemOnDisplayHover = true) : false" />
      <div v-if="itemOnDisplayHover" class="hover-info border border-secondary z-20 w-[240px]">
          Es werden nur bereitgestellte Altgeräte mitgenommen. Ihr ausgewählter Lieferort ist der Abholort der Ware. 
          Der Transport und die Entsorgung des Altgerätes sind kostenlos.
      </div>
    </div>
  </template>
</template>
<script setup lang="ts">
import { getExpArticle } from "~/composables/ExpApi/expArticle";
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  warranties: {
    type: Object
  },
  containerClass: {
    type: String,
    default: "col-start-1 lg:col-start-2 p-4 pt-0 lg:px-0 -mt-4 lg:col-span-3 col-span-5 font-bold"
  },
  showIcon: {
    type: Boolean,
    default: true
  },
  infoBeforePrice: {
    type: Boolean,
    default: false
  },
  showOldApplianceRemoval: {
    type: Boolean,
    default: true
  },
  monthlyOnly: {
    type: Boolean,
    default: false
  },
  articleId: {
    type: String
  }
});
const item = ref(props.item)
function getItem(){
  useCurrentShoppingCart().value?.itemList?.forEach(element => {
    if (element.articleId == props.articleId) {
      item.value = element
    }
  })
}
if(!item.value && props.articleId) {
  getItem()
  watch(() => useCurrentShoppingCart(), () => {
    getItem()
  })
}

let warranties = ref(props.warranties)
if(!props.warranties) {
  const { data: usedArticle } = await getExpArticle(props?.item?.articleId);
  const {data: warrantyList} = await useFetch('/api/warranty', {
    query: {
      webcode: usedArticle?.value?.webcode,
      price: props?.item?.price?.gross,
      productType: usedArticle?.value?.productType
    }
  })
  warranties = warrantyList
}

const itemOnDisplayHover = ref(false);
const showWarranty = ref('')
const warrantyTitle = ref('')
const warrantyDescription = ref('')
const warrantyUrl = ref([])
function openWarrantyInformation(warrantyEntry) {
  if (isProxy(warrantyEntry)) {
    warrantyEntry = toRaw(warrantyEntry)
  }

  showWarranty.value = warrantyEntry.typeName
  warrantyTitle.value = warrantyEntry.title
  warrantyDescription.value = warrantyEntry.description
  const ob = {}
  const urlCollection = new Array()
  for (let a = 0; a < warrantyEntry.listOfFiles.list.length; a++) {
    ob.fileName = warrantyEntry.listOfFiles.list[a].fileName
    ob.fileUrl = warrantyEntry.listOfFiles.list[a].fileUrl
    console.log('file objects', ob)
    urlCollection.push(ob)
  }
  warrantyUrl.value = urlCollection
}
</script>