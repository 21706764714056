<template>
  <nav
    class="super-navbar !visible lg:flex-grow basis-[100%] items-center xl:!flex xl:basis-auto text-paragraph"
    :class="showSuperMenu ? ' mobile' : ' hidden'"
  >
    <ul id="superNav"
        class="super-navbar-nav list-style-none flex flex-col pl-0 lg:flex-row justify-between"
    >
      <li class="nav-item">
        <NuxtLink :external="external" :to="'/'"
          class="nav-link whitespace-nowrap flex items-center lg:cursor-default justify-between px-2 py-4 relative hover:flyout-indicator indicator-primary"
          :class="{ 'flyout-indicator': openSuperDropdown === '0'}"
          title="Unsere Produkte">
          <template v-if="showSuperMenu">
            <div class="flex-initial w-full"
            @click.prevent="emit('showMegaMenu'); openSuperDropdown == '1' ? (openSuperDropdown = '0'): (openSuperDropdown = '1')">Unsere Produkte</div>
          </template>
          <template v-else>Unsere Produkte</template>
          <span
            class="lg:ml-1 px-4 lg:px-0 duration-300 ease-in-out lg:hidden"
            @click.prevent="emit('showMegaMenu'); openSuperDropdown == '1' ? (openSuperDropdown = '0'): (openSuperDropdown = '1')"
            :class="openSuperDropdown == '1' ? 'rotate-180 text-primary' : ''"
            ><BasicIcon :name="'chevron-down'" iconclass="w-6 h-6" />
          </span>
        </NuxtLink>
        <slot name="megamenu"/>
      </li>
      <li v-for="superCategory in superCategoryTree" class="nav-item" :class="'group'"
        :key="superCategory?.id || superCategory?.extId">
        <NuxtLink :external="external"
          class="nav-link whitespace-nowrap flex items-center justify-between px-2 py-4"
          :class="superCategory?.descendants?.length > 0 ? ' dropdown' : ''" target="_self"
          :to="superCategory?.url" :title="superCategory?.alt" :id="'dropdownMenu_' + superCategory?.id">
          {{ superCategory?.name }}
          <span v-if="superCategory?.descendants?.length > 0"
            class="w-5 lg:ml-2 lg:hidden duration-300 ease-in-out"
            :class="openSuperDropdown == superCategory?.id ? 'rotate-90' : ''"
            @click.prevent="openSuperDropdown == superCategory?.id ? (openSuperDropdown = '0') : (openSuperDropdown = superCategory?.id)">
            <BasicIcon :name="'chevron-right'" class="w-6 h-6 lg:hidden" />
          </span>
        </NuxtLink>
        <template v-if="superCategory?.descendants?.length > 0">
          <div class="dropdown-container lg:container lg:mx-auto absolute transition invisible duration-500 ease-in-out group-hover:transform transform right-0 left-0 group-hover:opacity-100 group-hover:visible _z-10"
            :id="'dropdownContainer_' + superCategory?.id"
            :class="openSuperDropdown == superCategory?.id ? ' !visible opacity-1' : ' opacity-0 hidden xl:block'">
            <ul class="navbar-nav list-style-none flex flex-col pl-0 lg:flex-row justify-between">
              <template v-for="superChild in superCategory?.descendants"
                :key="superChild.id || superChild?.extId">
                <li class="nav-item">
                  <NuxtLink :external="external" class="nav-link px-2 py-4 lg:px-3 lg:py-5" target="_self"
                    :to="superChild.url" :title="superChild.name">{{ superChild.name }}</NuxtLink>
                </li>
              </template>
            </ul>
          </div>
        </template>
      </li>
      <template v-if="superMenuItemLength < 5">
        <li v-for="campaign in campaignItems" class="nav-item group">
          <NuxtLink class="nav-link whitespace-nowrap flex items-center justify-between px-2 py-4" :to="campaign?.url" :title="campaign?.alt">
            {{ campaign?.name }}
          </NuxtLink>
        </li>
      </template>
      <template v-else>
        <li v-for="campaign in campaignItems.filter(campaignItem => campaignItem.topLevelMenu)" class="nav-item group">
          <NuxtLink class="nav-link whitespace-nowrap flex items-center justify-between px-2 py-4" :to="campaign?.url" :title="campaign?.alt">
            {{ campaign?.name }}
          </NuxtLink>
        </li>
        <li class="nav-item group">
          <div
            id="dropdownMenu_campaigns"
            class="nav-link whitespace-nowrap flex items-center justify-between px-2 py-4 cursor-pointer dropdown relative hover:flyout-indicator indicator-primary"
            :class="{ 'flyout-indicator': openSuperDropdown === 'campaigns'}"
            @click.prevent="openSuperDropdown === 'campaigns' ? (openSuperDropdown = '0') : (openSuperDropdown = 'campaigns')">
            Weitere Kampagnen
            <BasicIcon :name="'chevron-down'" class="w-6 h-6" />
          </div>
          <div
            id="dropdownContainer_campaigns"
            class="dropdown-container lg:container lg:mx-auto absolute transition invisible duration-500 ease-in-out group-hover:transform transform right-0 left-0 group-hover:opacity-100 group-hover:visible _z-20"
            :class="openSuperDropdown === 'campaigns' ? '!visible opacity-1' : 'opacity-0 hidden xl:block'">
            <ul
              class="navbar-nav list-style-none flex flex-col pl-0 lg:flex-row">
              <template v-for="campaign in campaignItems.filter(campaignItem => !campaignItem.topLevelMenu)" :key="campaign.id">
                <li class="nav-item">
                  <NuxtLink
                    class="nav-link px-2 py-4 lg:px-3 lg:py-5"
                    target="_self"
                    :to="campaign.url"
                    :title="campaign.name">{{ campaign.name }}
                  </NuxtLink>
                </li>
              </template>
            </ul>
          </div>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { useSuperMenuState, useSuperMenuDropdownState } from "~/composables/states";
import type { MenuLink } from "~/models/Navigation";
import type { Campaign } from "~/models/Promotions";

interface Props {
  superCategoryTree: Array<MenuLink>;
  external?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  external: false,
});

const emit = defineEmits(['showMegaMenu']);

const openSuperDropdown = useSuperMenuDropdownState();
const showSuperMenu = useSuperMenuState();

const campaignQuery = computed(() => {
  return {
    storeId: useCurrentStore().value?.id,
  }
});

const { data: campaignsForStore } = await useFetch<Array<Campaign>>('/api/promotions/campaigns', {
  query: campaignQuery
});

const campaignItems = computed<Array<MenuLink>>(() => {
  return campaignsForStore && campaignsForStore.value ? getMenuLinksFromCampaigns(campaignsForStore.value) : [];
});

const getMenuLinksFromCampaigns = (campaigns: Array<Campaign>): Array<MenuLink> => {
  return campaigns.map(campaign => {
    return {
      name: campaign.name,
      url: `/shop/Aktionen/${campaign.slug}`,
      topLevelMenu: (campaign.name === 'Aussteller'),
    } as MenuLink;
  });
}

const superMenuItemLength = computed(() => {
  const superCategoryTreeLength = props?.superCategoryTree?.length ?? 0;
  const campaignItemsLength = campaignItems?.value?.length ?? 0;

  return superCategoryTreeLength + campaignItemsLength;
})
</script>