<template>
  <template v-if="cart !== undefined && cart?.itemList?.length > 0">
    <div class="max-h-[50vh] overflow-auto">
      <template v-if="cartType == 'RESERVATION_CART'">
        <NuxtLink v-for="item in cart.itemList" :key="item" :title="item?.expArticle?.data?.title"
          :to="baseDir + item?.expArticle?.data?.slug"
          class="name grid grid-cols-4 font-bold gap-4 p-4 items-center border-b">
          <div class="image">
            <ArticleExpMedium :medium-id="item?.imgsrc" preset="ppmsmall" />
          </div>
          <div class="name text-ellipsis whitespace-nowrap overflow-hidden grow col-span-2">
              {{ item?.expArticle?.data?.title }}
          </div>
          <div class="price text-right">
            {{ parseFloat((item.price.gross/* + item.additionalPriceSum*/) * item.quantity).toFixed(2) }}&nbsp;€</div>
        </NuxtLink>
      </template>
      <template v-if="cartType == 'SHOPPING_CART'">
        <div v-for="item in cart.itemList" :key="item" class="border-b">
          <NuxtLink :title="item?.expArticle?.title"
          :to="baseDir + item?.expArticle?.slug"
          class="name grid grid-cols-4 font-bold gap-4 p-4 items-center ">
          <!-- {{ item?.expArticle }} -->
          <div class="image">
            <ArticleExpMedium :medium-id="item?.imgsrc" preset="ppmsmall" />
          </div>
          <div class="name text-ellipsis whitespace-nowrap overflow-hidden grow col-span-2">
              {{ item?.expArticle?.title }}
          </div>
          <div class="price text-right">
            {{ parseFloat((item.price.gross/* + item.additionalPriceSum*/) * item.quantity).toFixed(2) }}&nbsp;€
          </div>
        </NuxtLink>
      </div>
      </template>
      <template v-if="cartType === 'SHOPPING_CART' || cartType === 'RESERVATION_CART'">
        <div class="shoppingcart-calculation border-b solid border-secondary flex flex-col gap-2 py-4">
          <div class="flex justify-between px-4">
            <div>Zwischensumme <span v-if="cartType === 'RESERVATION_CART'" class="text-[#8b8b8b] text-xs">(inkl. Garantie/Versicherung)</span></div>
            <div class="text-right">
              {{ (function(){
                let additionalItemsPrice = 0
                if(cartType === 'RESERVATION_CART') {
                  for(let i = 0; i < cart?.itemList?.length; i++) {
                    let item = cart.itemList[i]
                    for(let j = 0; j < item?.additionalItems?.items?.length; j++) {
                      let additionalItem = item?.additionalItems?.items[j]
                      additionalItemsPrice = additionalItemsPrice + additionalItem?.price?.gross * item?.quantity
                    }
                  }
                }
                return cart.subTotalBill + additionalItemsPrice
              })().toFixed(2) }} €
            </div>
          </div>
          <template v-if="cartType === 'SHOPPING_CART'">
            <div v-for="(price, name) in (function(){
              let additionalItems = {}
              for(let i = 0; i < cart?.itemList?.length; i++) {
                let item = cart.itemList[i]
                for(let j = 0; j < item?.additionalItems?.items?.length; j++) {
                  let additionalItem = item?.additionalItems?.items[j]
                  if(additionalItem?.price?.gross > 0) {
                    if(!additionalItems[additionalItem?.itemOnDisplayDescription]) additionalItems[additionalItem?.itemOnDisplayDescription] = additionalItem?.price?.gross * item?.quantity
                    else additionalItems[additionalItem?.itemOnDisplayDescription] += additionalItem?.price?.gross * item?.quantity
                  }
                }
              }
              return additionalItems
            })()" :key="name" class="flex justify-between px-4">
              <!--
                I hate doing that: <div>{{ additionItem?.itemOnDisplayDescription?.replace("ae", "ä") }}</div>  
                but the data we get here does not include a variant of the name with an ä instead of ae and I kinda didn't 
                to change anything about the Warranty Process.
              -->
              <div>{{ name?.replace("ae", "ä") }}</div>
              <div class="text-right">{{ price?.toFixed(2) }}&nbsp;€</div>
            </div>
          </template>
          <template v-if="cartType === 'SHOPPING_CART'">
            <template v-for="(price, key) in (function(){
              let shippingMethodOptions = {}
              modifiedCartResult?.forEach(bin => {
                let name = gimmeDisplayName(bin?.shippingMethodOption)
                if(shippingMethodOptions[name] != undefined) shippingMethodOptions[name] = shippingMethodOptions[name] + bin?.price?.gross
                else shippingMethodOptions[name] = bin?.price?.gross
              })
              return shippingMethodOptions
            })()" :key="key">
              <div class="flex justify-between px-4">
                <div>{{ key }}</div>
                <div class="text-right" v-if="price !== 0">
                  {{ parseFloat(price).toFixed(2) }} €
                </div>
                <div class="text-right text-success font-bold" v-else>
                  GRATIS
                </div>
              </div>
            </template>
          </template>
          <template v-for="(entry, index) in promotionDiscounts" :key="index">
            <div class="flex justify-between px-4" v-if="cartType !== 'RESERVATION_CART' && entry?.discount !== 0">
              <div>
                {{ entry.name }}
              </div>
              <div class="text-right  text-primary">
                {{ entry.discount.toFixed(2) }}€
              </div>
            </div>
          </template>
          <template v-if="cart?.tax != null">
            <template v-for="[key,value] in cart.tax" :key="key">
              <div class="flex justify-between px-4" >
                <div>Enthaltene MwSt. {{ key.toFixed(0) }}%</div>
                  <div class="text-right">{{ value.toFixed(2) }}€</div>
              </div>
            </template>
        </template>
          <hr />
          <div class="flex justify-between font-extrabold text-xl px-4">
            <div>Gesamtbetrag</div>
            <div>{{ parseFloat(getCartTotal(cart) + totalBill).toFixed(2) }} €</div>
          </div>
          <div class="flex justify-between px-4"
            v-if="cartType !== 'RESERVATION_CART' && modifiedCartResult?.modifiedShippingCosts > 0">
            <div>
              Ihre gesparten Versandkosten
            </div>
            <div class="text-right text-success">
              {{ modifiedCartResult?.modifiedShippingCosts }} €
            </div>
          </div>
          <div v-if="cartType !== 'RESERVATION_CART' && Object.values(additionalItems).filter(e => e > 0).length" class="p-4">
            <div class="text-xl font-extrabold">
              Folgekosten pro Monat <span class="text-paragraph-light text-xs">(inkl. VerSt.)</span>
            </div>
            <div v-for="(price, name) in additionalItems" :key="name" class="flex justify-between">
              <div>
                {{ name }}
                <span>
                  &nbsp;
                  <BasicIcon name="circle-info-light" class="w-4 tooltip-top align-text-bottom" data-html="true"
                    data-tooltip="Nach dem Kaufabschluss&#160;erhältst du alle weiteren Informationen separat von der Wertgarantie&#160;SE." />
                </span>
              </div>
              <div class="text-right">{{ price?.toFixed(2) }}&nbsp;€</div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <template v-if="cartType === 'RESERVATION_CART'">
      <NuxtLink :external="useFeatureState('ES-5386-Reservation').value == false" :to="useFeatureState('ES-5386-Reservation').value ? '/shop/checkout/reservation?step=1' : '/#1946cb49-f42c-4e42-b13a-05016fed4c59'"
        class="btn-primary text-lg py-4 text-center block">
        <BasicIcon :name="'cart-shopping-solid'" class="w-4 mr-2 text-primary-contrast" />
        Reservieren
      </NuxtLink>
    </template>
    <template v-if="cartType === 'SHOPPING_CART'">
      <NuxtLink :to="'/shop/checkout?step=1'" class="btn-primary text-lg py-4 text-center block">
        <BasicIcon :name="'cart-shopping-solid'" class="w-4 mr-2 text-primary-contrast" />
        Zum Warenkorb
      </NuxtLink>
    </template>
  </template>
</template>
<script setup lang="ts">
import { inject } from "vue";
import {
getExpertArticle
} from "~/composables/ExpApi/expCache";
import { getAllShippingMethodOptions } from "~/composables/ExpApi/expCartActions";
import { getExpUserProfile } from "~/composables/ExpApi/expUser";
import { getExpArticle } from "~/composables/ExpApi/expArticle";
const props = defineProps({
  cart: {
    type: Object,
  },
  cartType: {
    type: String,
  },
  baseDir: {
    type: String,
    default: () => inject("baseDir"),
  },
});
let totalBill = 0;
let costMap = new Map();
const promotionDiscounts = ref()
const modifiedCartResult = ref()
modifiedCartResult.value = await getAllMissingData(props?.cart);
function gimmeDisplayName(fromName) {
  switch (fromName) {
    case 'WOL':
      return "Wunschortlieferung"
    case 'BSK':
      return "Bordsteinkantenlieferung"
    case 'SBS':
      return "Side-By-Side";
    case 'PKG':
      return "Paketversand"
    case 'SGPKG':
      return "Sperrgutpaket"
    case 'ESD':
      return 'Versand per E-Mail'
    default:
      return fromName;
  }
}
async function getAllMissingData(currentCart: any) {
  props.cart.tax = new Map()
  let modifiedCart = {};
  if (currentCart?.itemList !== undefined && currentCart?.itemList?.length > 0) {
    if (currentCart?.cartType === "RESERVATION_CART") {
      modifiedCart = useCurrentReservationCart().value
      calcProductTax()
      combineCartWithModifiers(modifiedCart);
      for (let b = 0; b < currentCart?.itemList?.length; b++) {
        let article = await getExpertArticle(currentCart?.itemList[b]?.articleId);
        currentCart.itemList[b].expArticle = article;
        const articleImage = article?.data?.value?.primaryImage;
        if (articleImage !== undefined) {
          currentCart.itemList[b].imgsrc = articleImage;
        }
        checkForCoupons();
      }
    }
    if (currentCart?.cartType === "SHOPPING_CART") {
      const userProfile = useCurrentUserState().value;
      modifiedCart = await calculateFakeShippingBins(
        currentCart?.itemList,
        userProfile?.csrfToken
      );
      calcProductTax()
      combineCartWithModifiers(modifiedCart);
      let promoMap = new Map()
      let taxMap = new Map()
      if(props?.cart?.tax) {
        taxMap = props?.cart?.tax
      }
      for (let b = 0; b < currentCart?.itemList?.length; b++) {
        let article = await getExpertArticle(currentCart?.itemList[b]?.articleId);
        currentCart.itemList[b].expArticle = article?.data;
        const articleImage = article?.data?.value?.primaryImage;
        if (articleImage !== undefined) {
          currentCart.itemList[b].imgsrc = articleImage;
        }
        for (var key in currentCart?.itemList[b]?.promotions) {
          let taxRate = currentCart?.itemList[b]?.promotions[key]?.taxRate * 100
          let taxDifference = (currentCart?.itemList[b]?.promotions[key]?.gross - currentCart?.itemList[b]?.promotions[key]?.net) * parseInt(currentCart?.itemList[b]?.quantity)
          let singlePromo = currentCart?.itemList[b]?.promotions[key]
          if (promoMap.get(key) == undefined) {
            promoMap.set(key, singlePromo.gross * currentCart?.itemList[b]?.quantity)
            
          } else {
            promoMap.set(key, (singlePromo.gross * currentCart?.itemList[b]?.quantity) + promoMap.get(key))
          }
          if(taxMap.get(taxRate) == undefined) {
            taxMap.set(taxRate, parseFloat(taxDifference.toFixed(2)))

          } else {
            let number = taxMap.get(taxRate)
            taxMap.set(taxRate, (parseFloat(taxDifference.toFixed(2)) + number))
          }
        }
      }
      props.cart.tax = taxMap
      let promoOutput = new Array()
      
      for (let key of promoMap.keys()) {
        let splitted = key.split(':')
        let promoId
        let modificationId
        if (splitted.length > 2) {
          promoId = splitted[1]
          modificationId = splitted[2]

        } else if (splitted.length == 2) {
          promoId = splitted[0]
          modificationId = splitted[1]
        } else {
          promoId = splitted[0]
        }
        let promotion = useActivePromotions()?.value?.find(promo => promo.id == promoId && (modificationId == undefined || promo?.orderModification?.find(mod => mod.id == modificationId) != undefined));
        let title = promotion?.title;
        
        promoOutput.push({
          name: title,
          discount: promoMap.get(key)
        })
      }
      promotionDiscounts.value = promoOutput
    }

    let shippingMethodOptionArray = new Array<string>()
    for (let a = 0; a < modifiedCart?.length; a++) {
      shippingMethodOptionArray.push(modifiedCart[a].shippingMethodOption)
    }
    if (shippingMethodOptionArray.length > 0) {
      let freshUser = await getExpUserProfile();
      let defaultPrices = {}
      const options = await getShippingMethodOptionsByIds(freshUser?.csrfToken, shippingMethodOptionArray);
      if (options?.value && options?.value.length > 0) {
        for (let a = 0; a < options?.value.length; a++) {
          defaultPrices[options?.value[a]?.id] = options?.value[a]?.defaultPrice?.gross
        }
      }
      let save = 0
      modifiedCart?.forEach(bin => {
        if(defaultPrices[bin.shippingMethodOption] > bin?.price?.gross)
          save += defaultPrices[bin.shippingMethodOption] - bin?.price?.gross
      });
      modifiedCart.modifiedShippingCosts = save.toFixed(2)
    }
    return modifiedCart;
  }


}


function checkForCoupons() {
  if (props?.cart?.coupons !== undefined && props?.cart?.coupons.length > 0) {
    for (let a = 0; a < props?.cart?.coupons.length; a++) {
      if (costMap.get(props?.cart?.coupons[a].couponCode === undefined)) {
        costMap.set(
          props?.cart?.coupons[a].couponCode,
          props?.cart?.coupons[a].discountRange.discount
        );
        totalBill += props?.cart?.coupons[a].discountRange.discount;
      }
    }
  }
}

function calcProductTax() {
  let cart = props?.cart
  let taxMap = new Map()
  if(props?.cart?.tax) {
    taxMap = props?.cart?.tax
  }
  for(var i = 0; i < cart?.itemList?.length; i++) {
    let item = cart?.itemList[i]
    let tax = (item?.price?.gross - item?.price?.net) * item.quantity
    let taxRate = item.price?.taxRate * 100
    if(taxMap.get(taxRate) == undefined) {
      taxMap.set(taxRate, tax)
    } else {
      let number = taxMap.get(taxRate)
      taxMap.set(taxRate, (tax + number))
    }
  }
  props.cart.tax = taxMap
}

function combineCartWithModifiers(modifiedCart: any) {
  if (modifiedCart) {
    for (let a = 0; a < modifiedCart.length; a++) {
      if (modifiedCart[a].price !== undefined && modifiedCart[a].price?.gross !== undefined) {
        totalBill += modifiedCart[a]?.price?.gross
        let steuer = modifiedCart[a]?.price?.gross - modifiedCart[a]?.price?.net
        let taxMap = new Map()
        if(props?.cart?.tax) {
          taxMap = props?.cart?.tax
        }
        for(var i = 0; i < modifiedCart[a].price?.taxValues?.length; i++) {
          let taxRate = modifiedCart[a].price?.taxValues[i].taxRate * 100
          
          if(taxMap.get(taxRate) == undefined) {
            taxMap.set(taxRate, parseFloat(modifiedCart[a].price?.taxValues[i].taxes))
          } else {
            let number = taxMap.get(taxRate)
            taxMap.set(taxRate, (parseFloat(modifiedCart[a].price?.taxValues[i].taxes) + number))
          }
        }
        props.cart.tax = taxMap
      }
    }
  }
}

function getMOdificationTitle(obj: any) {
  if(obj?.localizedProperties?.['de_DE|SHOP'] && obj?.localizedProperties?.['de_DE|SHOP'].title && obj?.localizedProperties?.['de_DE|SHOP'].title != "")
    return obj?.localizedProperties?.['de_DE|SHOP'].title
  for (let j = 0; j < obj?.localizedProperties?.['@arrayMap']?.length; j++) {
    if (obj?.localizedProperties?.['@arrayMap'][j].key == 'de_DE|SHOP') {
      return obj?.localizedProperties?.['@arrayMap'][j].value?.title;
    }
  }
  return findLocalizedProperties(obj)?.title;
}

let articleWarranties = {}
const additionalItems = ref(await getAdditionalItems(props.cart))
watch(() => props.cart, async () => {
  additionalItems.value = await getAdditionalItems(props.cart)
})
async function getAdditionalItems(cart) {
  let additionalItems = {}
  for(let j = 0; j < cart?.itemList?.length; j++) {
    let item = cart?.itemList[j]
    for(let k = 0; k < item?.additionalItems?.items?.length; k++) {
      let additionalItem = item?.additionalItems?.items[k]
      if(additionalItem?.price?.gross <= 0 && additionalItem.id.indexOf('OLD_APPLIANCES_REMOVAL') < 0) {
        let warranties
        if(articleWarranties[item?.articleId]) warranties = articleWarranties[item?.articleId]
        else {
          const { data: usedArticle } = await getExpArticle(item?.articleId);
          const {data: warrantyList} = await useFetch('/api/warranty', {
            query: {
              webcode: usedArticle?.value?.webcode,
              price: item?.price?.gross,
              productType: usedArticle?.value?.productType
            }
          })
          warranties = getRaw(warrantyList.value).warrantyList
          articleWarranties[item?.articleId] = warranties
        }
        for(let l = 0; l < warranties?.length; l++) {
          if(warranties[l].typeName == additionalItem.id) {
            if(!additionalItems[additionalItem?.itemOnDisplayDescription]) additionalItems[additionalItem?.itemOnDisplayDescription] = parseFloat(warranties[l]?.monthlyBruttoPrice) * item?.quantity
            else additionalItems[additionalItem?.itemOnDisplayDescription] += parseFloat(warranties[l]?.monthlyBruttoPrice) * item?.quantity
          }
        }
      }
    }
  }
  return additionalItems
}
</script>
